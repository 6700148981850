import React from "react"
import Service from "../../components/Shared/Service"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import globalbank from "../../assets/icons/noun_Global bank_3570148.svg"
import riskManagement from "../../assets/icons/noun_Risk Management_3262435.svg"
import raas from "../../assets/icons/noun_Check_3974112.svg"

import { graphql } from "gatsby"

const seoTitle = "Banking and Capital Markets "
const seoDescription =
  " Atyeti offers solutions to Banking and Financial institutions to accurately gauge risk, drive compliance and improve security."
const keyword =
  "Banking and Capital Markets, Client onboarding, KYC compliance, AML, Consolidated Audit Trail, Reconciliation and Reporting, Multi regulation, Cross Asset, Digitization"
const mainHeading = "Banking and Capital Markets"
const mainPara = `Transform front-to-back operations to make them future-ready and resilient. Our expertise can help modernize your technology and operations increasing your profitability and improving client experience.`

const serviceIcons = [
  { id: 1, name: "Client Lifecycle Management", icon: checkMark },
  { id: 2, name: "Reconciliation", icon: checkMark },
  { id: 3, name: " Regulatory reporting", icon: checkMark },
  { id: 4, name: "Lending Solutions", icon: checkMark },
  { id: 5, name: "Market Infrastructure -Trading Platforms", icon: checkMark },
  { id: 6, name: "Asset Management", icon: checkMark },
  { id: 7, name: "Operations", icon: checkMark },
  { id: 8, name: "Cloud for Financial Services", icon: checkMark },
]

// const section2Heading = `Featured Services`
const section2Para = `Atyeti has deep expertise in Banking and Capital markets in both IT and Operations. Leverage new technologies to drive growth and reduce cost of operations. `

const cardItems = [
  {
    id: 1,
    icon: riskManagement,
    heading: `Regulatory Reporting as a Service`,
    listItems: (
      <>
        <li>Regulatory reporting optimization through managed services.</li>
        <li>
          Regulatory reporting departments today face challenges in adhering to
          a constantly changing regulatory agenda.
        </li>
        <li>
          Atyeti has expertise in report production, process automation, report
          testing as a service supporting Global regulations etc.
        </li>
        <li>
          Our global, cross-asset, multi-regulation capabilities fulfill the
          needs of firms across all markets, including buy-side, sell-side and
          market infrastructure providers{" "}
        </li>
      </>
    ),
  },
  {
    id: 2,
    icon: raas,
    heading: `Client Lifecycle Management`,
    listItems: (
      <>
        <li>
          The know-your-customer (KYC) process is central to the efforts to
          stamp out money laundering.
        </li>
        <li>
          The accuracy of an institution’s anti-money-laundering (AML) efforts
          have far-reaching consequences. Failure to identify bad actors can
          result in enforcement actions by regulators.
        </li>
        <li>
          Atyeti has expertise in Fenergo – which is an end-to-end KYC solution
          composed of industry best practices.
        </li>
        <li>
          This SaaS-based solutions empowers financial institutions to digitally
          transform the client experience throughout the client lifecycle
        </li>
        <li>
          Atyeti’s teams can help with implementations to manage compliance with
          KYC, AML, and other regulatory compliance
        </li>
      </>
    ),
  },
  {
    id: 3,
    icon: globalbank,
    heading: `Lending`,
    listItems: (
      <>
        <li>
        Atyeti is a Finastra Fusion Orbit Affiliate Global Implementation partner for System Integrations with specific capability in Data Migrations, Design, Installation, Configuration, Enhancement, Upgrade and Support services for major global banks leveraging the Loan IQ platform.
        </li>
        <li>
          Deep knowledge in all aspects of lending operations, from high-volume
          Commercial Lending to bilateral and complex Syndicated Lending and
          Secondary Loan market, Loan Accounting, Regulatory domain, and
          Reporting etc{" "}
        </li>
        <li>
          Managing Risk and Regulatory Requirements.
    <ul>      <li>
            We can help get the most out of your data, with insights and
            reporting that enables you to manage risk effectively and create
            tailored solutions for your customers.
          </li>
          <li>
            Automated controls to ensure compliance, reconciliation and avoid
            reputational and financial damage related to, but not limited, to US
            Federal and European regulations.
          </li></ul>
        </li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    className="risk-and-regulatory"
  />
)
export const query = graphql`
  query RiskRegulatoryComplianceCaseQuery {
    casestudyJson(mainheading: { eq: "banking-and-capital" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
